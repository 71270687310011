@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    display: flex;
    flex-direction: column;
    justify-content:center;
}
